import styled from 'styled-components';
import { device } from 'assets/styles/theme';

export const BlogMenuBar = styled.div`
  height: 96px;
  margin-top: 32px;
  margin-bottom: 32px;
  border-top: 1px solid ${({ theme }) => theme.primary200};
  border-bottom: 1px solid ${({ theme }) => theme.primary200};
  background-color: ${({ theme }) => theme.primary50};
  display: flex;
  align-items: center;
`;

export const BlogMenuWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  flex-wrap: wrap;

  & a {
    padding: 4px;
    margin-left: 4px;
    margin-right: 4px;
    text-transform: uppercase;
    &:last-child {
      margin-right: 0px;
    }
    &:first-child {
      margin-left: 0px;
    }
    &.active {
      color: white;
      background-color: ${({ theme }) => theme.primaryColor};
    }
  }

  @media ${device.xss} {
  }

  @media ${device.md} {
    & a {
      padding: 8px;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
`;
