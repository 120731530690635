import styled from 'styled-components';
import { device } from 'assets/styles/theme';

export const ProductsContent = styled.div`
  text-align: left;
  @media ${device.xss} {
    margin-bottom: initial;
    font-size: 1.3em;
  }

  @media ${device.md} {
    margin-bottom: initial;
    font-size: 1.5em;
  }
`;

export const CenteredTextTitle = styled.div`
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.primary500};
  @media ${device.xss} {
    font-size: 1.5em;
  }

  @media ${device.md} {
    font-size: 1.5em;
  }
`;

export const PrimaryServices = styled.div`
  display: grid;
  gap: 64px;
  @media ${device.sm} {
    grid-template-columns: 1fr;
    margin-top: ${(props) => (props.lastItem ? '64px' : 'initial')};
  }
  @media ${device.md} {
    grid-template-columns: 1fr 3fr;
    margin-top: ${(props) => (props.lastItem ? '96px' : 'initial')};
  }
`;
export const PrimaryServiceImage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;

  @media ${device.md} {
    margin-top: 96px;
  }
  & img {
    height: 128px;
    width: 128px;
  }
`;
export const PrimaryServiceTextTitle = styled.div`
  font-weight: bold;
  margin-bottom: 48px;
  color: ${({ theme }) => theme.primary500};
  @media ${device.xss} {
    font-size: 1.5em;
    text-align: center;
  }

  @media ${device.md} {
    font-size: 1.5em;
    text-align: left;
  }
`;
export const PrimaryServiceTextArea = styled.div`
  @media ${device.md} {
    padding-left: 64px;
    padding-right: 64px;
  }
  @media ${device.lg} {
    padding-left: 96px;
    padding-right: 96px;
  }
  @media ${device.xl} {
    padding-left: 128px;
    padding-right: 128px;
  }
`;
export const ServiceParagraph = styled.p`
  font-size: 1.2em;
  margin-bottom: 48px;
`;
export const ServiceSubtitle = styled.div`
  font-weight: bold;
  margin-bottom: 24px;
  font-size: 1.4em;
`;

export const OtherServices = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 64px;
  @media ${device.md} {
    margin-top: 96px;
    padding-left: 64px;
    padding-right: 64px;
  }
  @media ${device.lg} {
    margin-top: 96px;
    padding-left: 96px;
    padding-right: 96px;
  }
  @media ${device.xl} {
    margin-top: 96px;
    padding-left: 128px;
    padding-right: 128px;
  }
  @media ${device.xxl} {
    margin-top: 96px;
    padding-left: 256px;
    padding-right: 256px;
  }
`;
