import { useContext } from 'react';
import AppContext from 'contexts/AppContext';

const useTheme = () => {
  const { theme, toggleTheme } = useContext(AppContext);

  return { toggleTheme, theme };
};

export default useTheme;
