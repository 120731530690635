import styled from 'styled-components';
import { device } from 'assets/styles/theme';
import { Form } from 'antd';

export const ContactContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  margin-top: 64px;
  margin-bottom: 64px;
  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InfoAndMap = styled.div``;
export const ContactForm = styled(Form)`
  padding: 48px;
  background-color: ${({ theme }) => theme.primary50};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.primary300};
  @media ${device.md} {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .ant-form-item-control-input-content {
      text-align: center;
    }

    & .ant-form-item {
      margin-bottom: 48px;
    }
  }
`;

export const ActionArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DirectionArea = styled.div`
  display: flex;
  flex-direction: column;
`;
