export const defaultValues = {
  primaryColor: '#002E63',
  grayColor: '#A3A19E',
  primary900: '#002E63',
  primary800: '#004594',
  primary700: '#005CC7',
  primary600: '#0074FA',
  primary500: '#2E8FFF',
  primary400: '#61AAFF',
  primary300: '#94C6FF',
  primary200: '#C7E1FF',
  primary100: '#EBF4FF',
  primary50: '#F5F9FF',
  secondary900: '#4D3100',
  secondary800: '#805200',
  secondary700: '#B07100',
  secondary600: '#E59300',
  secondary500: '#FFAD1A',
  secondary400: '#FFBF4C',
  secondary300: '#FFD180',
  secondary200: '#FFE4B3',
  secondary100: '#FFF8EB',
  gray900: '#3C3B39',
  gray800: '#565552',
  gray700: '#716E6B',
  gray600: '#8A8884',
  gray500: '#A3A19E',
  gray400: '#BCBBB8',
  gray300: '#D5D4D3',
  gray200: '#EEEDED',
  gray100: '#F5F5F5',
  defaultPadding: '192px',
};

export const lightTheme = {
  ...defaultValues,
  text: defaultValues.primaryColor,
  body: 'white',
};

export const darkTheme = {
  ...defaultValues,
  text: defaultValues.gray100,
  body: defaultValues.gray500,
};

const size = {
  xss: '320px',
  xs: '460px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

export const device = {
  xss: `(min-width: ${size.xss})`,
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
  xxl: `(min-width: ${size.xxl})`,
};

export const deviceMax = {
  xs: `(max-width: ${size.xs})`,
  sm: `(max-width: ${size.sm})`,
  md: `(max-width: ${size.md})`,
  lg: `(max-width: ${size.lg})`,
  xl: `(max-width: ${size.xl})`,
  xxl: `(max-width: ${size.xxl})`,
};
