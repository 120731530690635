// global.js
// Source: https://github.com/maximakymenko/react-day-night-toggle-app/blob/master/src/global.js#L23-L41

import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  #___gatsby,
  #gatsby-focus-wrapper {
    min-height: 100vh;
  }

  #gatsby-focus-wrapper {
    overflow: auto !important;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  body {
    overflow: overlay;
    height: 100vh;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
   transition: all 0.25s linear;
  }

  
  `;

export default GlobalStyles;
