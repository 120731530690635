import React from 'react';
import {
  OtherServiceItemDescription,
  OtherServiceItemImage,
  OtherServiceItemTexts,
  OtherServiceItemTitle,
  OtherServiceItemWrapper,
} from './OtherServiceItem.styles';

const OtherServiceItem = ({ title, image, description, lastItem = false }) => {
  return (
    <OtherServiceItemWrapper lastItem={lastItem}>
      <OtherServiceItemImage>
        <img src={image} alt="Otro servicio" />
      </OtherServiceItemImage>
      <OtherServiceItemTexts>
        <OtherServiceItemTitle>{title}</OtherServiceItemTitle>
        <OtherServiceItemDescription>{description}</OtherServiceItemDescription>
      </OtherServiceItemTexts>
    </OtherServiceItemWrapper>
  );
};

export default OtherServiceItem;
