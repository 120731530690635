import React from 'react';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from 'assets/styles/theme';
import useTheme from 'hooks/useTheme';
import GlobalStyles from 'assets/styles/global';

const Layout = ({ children }) => {
  const { theme } = useTheme();
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
};

export default Layout;
