import styled from 'styled-components';

export const ListDescriptionWrapper = styled.div``;
export const ListDescriptionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 24px;
`;
export const ListDescriptionList = styled.ul`
  padding: 0;
  list-style-type: none;
`;

export const ListDescriptionListItem = styled.li`
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  & .anticon {
    margin-right: 8px;
    color: ${({ theme }) => theme.secondary600};
  }
`;
