import React from 'react';
import { Button } from 'antd';
import {
  TeamOutlined,
  ThunderboltOutlined,
  UserSwitchOutlined,
  SearchOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import { navigate } from 'gatsby';
import HeroImage from 'assets/img/heroImage.svg';
import PrimaryServiceImage from 'assets/img/primaryService.png';

import MenuBar from 'components/MenuBar';
import PageSection from 'components/PageSection';
import SectionTitle from 'components/SectionTitle';
import Footer from 'components/Footer';

import Offer from './Offer';

import {
  AppContent,
  AppWrapper,
  CallToAcions,
  Hero,
  HeroInfo,
  HeroInfoDescription,
  HeroInfoImage,
  HeroInfoImageWrapper,
  HeroInfoTitle,
  MainSection,
  Offers,
  PrimaryServiceAction,
  PrimaryServiceDescription,
  PrimaryServiceImg,
  PrimaryServiceItem,
  PrimaryServiceItems,
  PrimaryServiceTexts,
  PrimaryServiceWrapper,
  WhatWeOfferSection,
} from './Home.styles';

const Home = () => {
  return (
    <AppWrapper>
      <MainSection>
        <MenuBar />
        <AppContent>
          <Hero>
            <HeroInfo>
              <HeroInfoTitle>
                Telecomunicaciones Bantel C.A.
                <br />
                Servicios de transporte satelital y terrestre de voz, video y datos
              </HeroInfoTitle>
              <HeroInfoDescription>
                Telecomunicaciones Bantel con más de 30 años en el mercado regresa para ofrecer
                servicios de transporte satelital y terrestre de voz, video y datos; con el fin de
                conectar de manera estable, segura y continua a todos nuestros clientes.
              </HeroInfoDescription>
              <CallToAcions>
                <Button type="primary" onClick={() => navigate('/about')}>
                  Leer más
                </Button>
                <Button onClick={() => navigate('/contact')}>Contácto</Button>
              </CallToAcions>
            </HeroInfo>
            <HeroInfoImageWrapper>
              <HeroInfoImage src={HeroImage} />
            </HeroInfoImageWrapper>
          </Hero>
        </AppContent>
      </MainSection>

      <PageSection colored>
        <WhatWeOfferSection>
          <span>Tenemos grandes cosas para ti</span>
          <SectionTitle style={{ marginTop: 12 }}>Qué te ofrecemos</SectionTitle>
          <Offers>
            <Offer
              title="Somos un equipo preparado"
              description="En Bantel cuentas con personas altamente capacitadas para lograr todas las conexiones que necesites a través de redes terrestres o satelitales."
              icon={<TeamOutlined />}
            />
            <Offer
              title="Estabilidad en tu conexión"
              description="Te garantizamos calidad de servicio y monitoreo constante para que te mantengas conectado siempre."
              icon={<ThunderboltOutlined />}
            />
            <Offer
              title="Atención personalizada"
              description="Contamos con personal dispuesto a brindar soluciones a todas tus inquietudes, dudas, solicitudes u observaciones."
              icon={<UserSwitchOutlined />}
            />
            <Offer
              title="Seguimiento del servicio"
              description="Nuestro equipo técnico y administrativo mantiene un acompañamiento  personalizado  al servicio  para que la experiencia cumplas tus expectativas."
              icon={<SearchOutlined />}
            />
          </Offers>
        </WhatWeOfferSection>
      </PageSection>

      <PageSection>
        <PrimaryServiceWrapper>
          <PrimaryServiceImg>
            <img src={PrimaryServiceImage} alt="Primary Service" />
          </PrimaryServiceImg>
          <PrimaryServiceTexts>
            <SectionTitle>Conoce nuestros servicios de enlaces</SectionTitle>
            <PrimaryServiceDescription>
              Tenemos servicios de enlaces terrestres y satelitales a tu dispocisión.
            </PrimaryServiceDescription>
            <PrimaryServiceDescription>Enlaces terrestres</PrimaryServiceDescription>
            <PrimaryServiceItems>
              <PrimaryServiceItem>
                <CheckCircleOutlined />
                <span>
                  Plan asimétrico de 20 Mbps de descarga y 10Mbps de carga, en enlace punto a punto.
                </span>
              </PrimaryServiceItem>
            </PrimaryServiceItems>
            <PrimaryServiceDescription>Enlace Satelital</PrimaryServiceDescription>
            <PrimaryServiceItems>
              <PrimaryServiceItem>
                <CheckCircleOutlined />
                <span>
                  Enlace satelital transaccional para Puntos de ventas, antenas de 0,74cm en la
                  modalidad hazlo tú mismo.
                </span>
              </PrimaryServiceItem>
            </PrimaryServiceItems>
            <PrimaryServiceAction>
              <Button type="primary" onClick={() => navigate('/products')}>
                Leer más
              </Button>
            </PrimaryServiceAction>
          </PrimaryServiceTexts>
        </PrimaryServiceWrapper>
      </PageSection>
      <Footer />
    </AppWrapper>
  );
};

export default Home;
