import React from 'react';

import MainImage from 'assets/img/aboutMain.png';
import TargetPeopleSectionImg from 'assets/img/targetPeopleImage.png';

import MainLayout from 'components/MainLayout';
import LayoutImageTitlePage from 'components/LayoutImageTitlePage';
import PageSection from 'components/PageSection';
import Footer from 'components/Footer';

import {
  AboutContent,
  AboutTextBoxTitle,
  AboutTextBoxWrapper,
  AboutWrapper,
  CenteredTextTitle,
  TargetPeopleSection,
  TargetPeopleSectionImgWrapper,
  TargetPeopleSectionText,
} from './About.styles';

const AboutTextBox = ({ title, description, align = 'left', lastItem = false }) => {
  return (
    <AboutTextBoxWrapper align={align} lastItem={lastItem}>
      <AboutTextBoxTitle>{title}</AboutTextBoxTitle>
      <div>{description}</div>
    </AboutTextBoxWrapper>
  );
};

const About = () => {
  return (
    <AboutWrapper>
      <MainLayout>
        <LayoutImageTitlePage
          title="Sobre Nosotros"
          subtitle="El mejor equipo con la mejor empresa. Conózcanos."
          image={MainImage}
        />
      </MainLayout>
      <PageSection>
        <AboutContent>
          <p>
            Actualmente somos una empresa preparada para asumir los retos del mercado, que busca
            ofrecer una red de transporte se gura, confiable, estable y continua, a través de
            plataformas respaldadas por nuestro conocimiento y años de experiencia.
          </p>
          <p>
            Trabajamos día a día por hacer mucho más amplia nuestra red terrestre y por incorporar
            plataformas satelitales que nos permiten extender nuestros servicios a todos los lugares
            en los que son escasas las opciones de conexión.
          </p>
        </AboutContent>
      </PageSection>
      <PageSection colored>
        <AboutTextBox
          title="Misión"
          description="Ofrecer a nuestros clientes plataformas tecnológicas de calidad, y un equipo humano especializado y dispuesto a brindar un excelente servicio, así como también procesos de gestión que garanticen seguridad y confiabilidad en nuestras redes."
        />
        <AboutTextBox
          title="Visión"
          align="right"
          lastItem
          description="Ser reconocidos como la principal referencia dentro de las empresas de telecomunicaciones en el mercado local e internacional; asimismo, buscamos contribuir con el desarrollo sustentable de las compañías y con el progreso de la sociedad, brindando servicios tecnológicos innovadores tanto en información como en comunicaciones."
        />
      </PageSection>
      <PageSection>
        <CenteredTextTitle>¿A quiénes estamos dirigidos?</CenteredTextTitle>
        <TargetPeopleSection>
          <TargetPeopleSectionImgWrapper>
            <img src={TargetPeopleSectionImg} alt="A quienes estamos dirigidos" />
          </TargetPeopleSectionImgWrapper>
          <TargetPeopleSectionText>
            A todos los sectores que tengan como necesidad mantenerse conectados.
          </TargetPeopleSectionText>
        </TargetPeopleSection>
      </PageSection>
      <Footer />
    </AboutWrapper>
  );
};

export default About;
