import { device } from 'assets/styles/theme';
import styled from 'styled-components';

export const SectionTitleWrapper = styled.div`
  font-weight: bold;

  @media ${device.xss} {
    font-size: 1.9em;
  }

  @media ${device.xs} {
    font-size: 2em;
  }
`;

export const SectionTitleText = styled.div``;
