import { device } from 'assets/styles/theme';
import styled from 'styled-components';

export const OtherServiceItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.lastItem ? 'initial' : '64px')};
`;

export const OtherServiceItemImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const OtherServiceItemTexts = styled.div``;
export const OtherServiceItemTitle = styled.div`
  font-weight: bold;
  margin-bottom: 12px;
  text-align: left;
  color: ${({ theme }) => theme.primary500};
  @media ${device.xss} {
    font-size: 1.2em;
  }

  @media ${device.md} {
    font-size: 1.5em;
  }
`;
export const OtherServiceItemDescription = styled.div``;
