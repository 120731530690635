import styled from 'styled-components';
import { device } from 'assets/styles/theme';

export const AboutContent = styled.div`
  text-align: left;
  @media ${device.xss} {
    margin-bottom: initial;
    font-size: 1.3em;
  }

  @media ${device.md} {
    margin-bottom: initial;
    font-size: 1.5em;
  }
`;

export const AboutTextBoxWrapper = styled.div`
  text-align: ${(props) => props.align};
  margin-bottom: ${(props) => (props.lastItem ? 'initial' : '96px')};
  @media ${device.xss} {
    font-size: 1.3em;
  }

  @media ${device.md} {
    font-size: 1.5em;
  }
`;

export const AboutTextBoxTitle = styled.div`
  font-weight: bold;
  margin-bottom: 24px;
`;

export const CenteredTextTitle = styled.div`
  text-align: center;
  font-weight: bold;
  @media ${device.xss} {
    font-size: 1.2em;
  }

  @media ${device.md} {
    font-size: 1.5em;
  }
`;

export const TargetPeopleSection = styled.div`
  margin-top: 96px;
  display: grid;
  gap: 48px;
  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TargetPeopleSectionImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 70%;
  }
`;

export const TargetPeopleSectionText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media ${device.xss} {
    font-size: 1.2em;
  }

  @media ${device.md} {
    font-size: 1.5em;
  }
`;

export const AboutWrapper = styled.div``;
