import { device } from 'assets/styles/theme';
import styled from 'styled-components';

export const MainLayoutWrapper = styled.div`
  @media ${device.xss} {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: ${({ noTopMargin }) => (noTopMargin ? '0px' : '32px')};
  }

  @media ${device.xs} {
    padding-left: 48px;
    padding-right: 48px;
    margin-top: ${({ noTopMargin }) => (noTopMargin ? '0px' : '48px')};
  }

  @media ${device.sm} {
    padding-left: 64px;
    padding-right: 64px;
    margin-top: ${({ noTopMargin }) => (noTopMargin ? '0px' : '64px')};
  }

  @media ${device.md} {
    padding-left: 96px;
    padding-right: 96px;
  }

  @media ${device.lg} {
    padding-left: 128px;
    padding-right: 128px;
  }

  @media ${device.xl} {
    padding-left: 192px;
    padding-right: 192px;
  }

  @media ${device.xxl} {
    padding-left: 384px;
    padding-right: 384px;
  }
`;

export const MainLayoutText = styled.div``;
