import styled from 'styled-components';
import { device } from 'assets/styles/theme';

export const LoadingErrorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 256px);
  justify-content: center;
`;

export const BlogHomeWrapper = styled.div``;
export const PostInfo = styled.div`
  display: flex;
  @media ${device.xss} {
    flex-direction: column;
    font-size: 0.9em;
    & span {
      display: none;
    }
  }

  @media ${device.md} {
    font-size: 1em;
    flex-direction: row;
    & span {
      display: block;
    }
  }
`;
export const Category = styled.div`
  color: ${({ theme }) => theme.secondary600};
  font-weight: bold;
`;

export const PostImage = styled.div`
  width: 100%;
  & img {
    width: 100%;
  }
`;

export const Author = styled.div`
  display: flex;
  align-items: center;
`;
export const AuthorData = styled.div`
  margin-left: 12px;
  font-size: 0.9em;
`;
export const AuthorName = styled.div`
  color: ${({ theme }) => theme.secondary600};
  font-weight: bold;
`;
export const Description = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
  font-style: italic;
  @media ${device.xss} {
    font-size: 1.5em;
  }

  @media ${device.md} {
    font-size: 1.5em;
  }
`;
export const Article = styled.div`
  margin-top: 48px;
  margin-bottom: 48px;
  @media ${device.xss} {
    font-size: 1.5em;
  }

  @media ${device.md} {
    font-size: 1.5em;
  }
`;
