import React from 'react';
import { Router } from '@reach/router';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import ThemeLayout from 'components/ThemeLayout';

import { strapi } from 'config';

import Home from 'routes/Home';
import Contact from 'routes/Contact';
import About from 'routes/About';
import Products from 'routes/Products';

import Blog from 'routes/Blog';
import BlogHome from 'routes/Blog/BlogHome';
import BlogArticlesList from 'routes/Blog/BlogArticlesList';

const client = new ApolloClient({
  uri: `${strapi}/graphql`,
  cache: new InMemoryCache(),
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ThemeLayout>
        <Router basepath="/">
          <Home path="/" />
          <Contact path="/contact" />
          <About path="/about" />
          <Products path="/products" />
          <Blog path="/blog">
            <BlogHome path="/" />
            <BlogArticlesList path="article-list" />
            <BlogHome path="article/:id" />
          </Blog>
        </Router>
      </ThemeLayout>
    </ApolloProvider>
  );
};

export default App;
