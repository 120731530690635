import { device } from 'assets/styles/theme';
import styled from 'styled-components';

export const LayoutImageTitlePageWrapper = styled.div`
  margin-top: 64px;
`;
export const LayoutImageTitlePageTitle = styled.div`
  color: ${({ theme }) => theme.primaryColor};
  font-weight: bold;
  text-align: center;

  @media ${device.xss} {
    font-size: 2.3em;
  }

  @media ${device.md} {
    font-size: 2.5em;
  }
`;
export const LayoutImageTitlePageSubtitle = styled.div`
  color: ${({ theme }) => theme.secondary600};
  text-align: center;

  @media ${device.xss} {
    font-size: 1em;
  }

  @media ${device.md} {
    font-size: 1.3em;
  }
`;

export const LayoutImageTitlePageImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.xss} {
    height: 250px;
  }

  @media ${device.md} {
    height: 500px;
  }
  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
