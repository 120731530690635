import styled from 'styled-components';
import { device } from 'assets/styles/theme';

export const AppWrapper = styled.div`
  min-height: 100vh;
`;

export const MainSection = styled.div`
  @media ${device.xss} {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 32px;
    min-height: calc(100vh - 32px);
  }

  @media ${device.xs} {
    padding-left: 48px;
    padding-right: 48px;
    margin-top: 48px;
    min-height: calc(100vh - 48px);
  }

  @media ${device.sm} {
    padding-left: 64px;
    padding-right: 64px;
    margin-top: 64px;
    min-height: calc(100vh - 64px);
  }

  @media ${device.md} {
    padding-left: 96px;
    padding-right: 96px;
  }

  @media ${device.lg} {
    padding-left: 128px;
    padding-right: 128px;
  }

  @media ${device.xl} {
    padding-left: 192px;
    padding-right: 192px;
  }

  @media ${device.xxl} {
    padding-left: 384px;
    padding-right: 384px;
  }
`;

export const AppContent = styled.div`
  min-height: calc(100vh - 192px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Hero = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const HeroInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
`;

export const HeroInfoTitle = styled.div`
  display: flex;
  color: ${({ theme }) => theme.primaryColor};
  font-weight: bold;

  @media ${device.xss} {
    margin-bottom: initial;
    font-size: 1.8em;
    text-align: center;
    // margin-bottom: 48px;
  }

  @media ${device.md} {
    margin-bottom: initial;
    font-size: 2em;
    text-align: left;
  }
`;

export const HeroInfoDescription = styled.div`
  display: none;

  color: ${({ theme }) => theme.primaryColor};
  @media ${device.sm} {
    display: flex;
    text-align: center;
    margin-top: 32px;
    font-size: 1.2em;
  }

  @media ${device.md} {
    text-align: left;
  }
`;

export const HeroInfoImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeroInfoImage = styled.img`
  height: 100%;
  width: 100%;
  max-width: 384px;
  flex: 1 1 0px;
`;

export const CallToAcions = styled.div`
  display: none;
  margin-top: 24px;
  & button:last-child {
    margin-left: 12px;
  }
  @media ${device.sm} {
    display: flex;
    justify-content: center;
  }

  @media ${device.md} {
    justify-content: flex-start;
  }
`;

export const WhatWeOfferSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Offers = styled.div`
  display: grid;
  margin-top: 96px;
  gap: 24px;
  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const PrimaryServiceWrapper = styled.div`
  display: grid;
  gap: 24px;
  @media ${device.sm} {
    grid-template-columns: 1fr;
  }
  @media ${device.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const PrimaryServiceImg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    width: 100%;
  }
`;
export const PrimaryServiceTexts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.sm} {
  }
  @media ${device.md} {
    align-items: flex-start;
  }
`;

export const PrimaryServiceDescription = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

export const PrimaryServiceItems = styled.ul`
  margin-bottom: 24px;
  list-style-type: none;
`;

export const PrimaryServiceItem = styled.li`
  display: flex;
  align-items: center;
  & .anticon {
    margin-right: 8px;
    color: ${({ theme }) => theme.secondary600};
  }
`;

export const PrimaryServiceAction = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
