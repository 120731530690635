import React from 'react';
import {
  OfferDescription,
  OfferIconArea,
  OfferTextArea,
  OfferTitle,
  OfferWrapper,
} from './Offer.styles';

const Offer = ({ icon, title, description }) => {
  return (
    <OfferWrapper>
      <OfferIconArea>{icon}</OfferIconArea>
      <OfferTextArea>
        <OfferTitle>{title}</OfferTitle>
        <OfferDescription>{description}</OfferDescription>
      </OfferTextArea>
    </OfferWrapper>
  );
};

export default Offer;
