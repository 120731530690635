import React from 'react';
import { Link } from '@reach/router';
import MainLayout from 'components/MainLayout';
import Footer from 'components/Footer';
import { BlogMenuBar, BlogMenuWrapper } from './Blog.styles';

// blog must be on small caps for avoid route matching problems
const blog = ({ children }) => {
  return (
    <>
      <MainLayout />
      <BlogMenuBar>
        <MainLayout showMenuBar={false} noTopMargin style={{ width: '100%' }}>
          <BlogMenuWrapper>
            <Link
              to="/blog"
              getProps={({ isCurrent }) => {
                return {
                  className: isCurrent ? 'active' : 'no-active',
                };
              }}
            >
              Última publicación
            </Link>
            <Link
              to="/blog/article-list"
              getProps={({ isCurrent }) => {
                return {
                  className: isCurrent ? 'active' : 'no-active',
                };
              }}
            >
              Lista de artículos
            </Link>
            <Link
              to="/blog/article-list"
              getProps={({ isCurrent }) => {
                return {
                  className: isCurrent ? 'active' : 'no-active',
                };
              }}
            >
              Lista de artículos
            </Link>
            <Link
              to="/blog/article-list"
              getProps={({ isCurrent }) => {
                return {
                  className: isCurrent ? 'active' : 'no-active',
                };
              }}
            >
              Lista de artículos
            </Link>
          </BlogMenuWrapper>
        </MainLayout>
      </BlogMenuBar>
      <MainLayout showMenuBar={false} noTopMargin>
        {children}
      </MainLayout>
      <Footer />
    </>
  );
};

export default blog;
