import React from 'react';
import MenuBar from 'components/MenuBar';
import { MainLayoutWrapper } from './MainLayout.styles';

const MainLayout = ({ children, showMenuBar = true, noTopMargin = false }) => {
  return (
    <MainLayoutWrapper noTopMargin={noTopMargin}>
      {showMenuBar && <MenuBar />}
      {children}
    </MainLayoutWrapper>
  );
};

export default MainLayout;
