import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { Alert, Avatar, Divider, Spin } from 'antd';
import Markdown from 'markdown-to-jsx';
import { LoadingOutlined } from '@ant-design/icons';
import { useReadingTime } from 'react-hook-reading-time';
import dayjs from 'dayjs';
import { strapi } from 'config';

import SectionTitle from 'components/SectionTitle';
import {
  Article,
  Author,
  AuthorData,
  AuthorName,
  BlogHomeWrapper,
  Category,
  Description,
  LoadingErrorWrapper,
  PostImage,
  PostInfo,
} from './BlogHome.styles';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const BlogHome = () => {
  const { loading, error, data } = useQuery(gql`
    # Write your query or mutation here
    query Articles {
      articles(sort: "created_at:ASC", limit: 1) {
        id
        created_at
        updated_at
        title
        description
        content
        category {
          name
        }
        image {
          url
        }
        author {
          name
          email
          picture {
            url
          }
        }
      }
    }
  `);

  if (loading)
    return (
      <LoadingErrorWrapper>
        <Spin tip="Cargando..." indicator={antIcon} />
      </LoadingErrorWrapper>
    );
  if (error)
    return (
      <LoadingErrorWrapper>
        <Alert
          message="Error"
          description={`Hubo un error intentando obtener el post más reciente del blog. Por favor contacte al administrador: ${error}`}
          type="error"
          showIcon
        />
      </LoadingErrorWrapper>
    );

  const post = data.articles[0];
  const { minutes } = useReadingTime(post.content);
  return (
    <BlogHomeWrapper>
      <PostInfo>
        <Category>{post.category.name}</Category>{' '}
        <span style={{ marginLeft: 8, marginRight: 8 }}>•</span>
        <div>Última actualización: {dayjs(post.updated_at).format('DD MMM, YYYY')}</div>
        <span style={{ marginLeft: 8, marginRight: 8 }}>•</span>
        <div>{minutes <= 1 ? `${minutes} minuto de lectura` : `${minutes} minutos de lectura`}</div>
      </PostInfo>
      <SectionTitle style={{ marginTop: 24, marginBottom: 24 }}>{post.title}</SectionTitle>
      <Author>
        <Avatar src={`${strapi}${post.author.picture.url}`} />
        <AuthorData>
          <AuthorName>{post.author.name}</AuthorName>
          <div>{post.author.email}</div>
        </AuthorData>
      </Author>
      <Divider />
      <PostImage>
        <img src={`${strapi}${post.image.url}`} alt="Imagen del post" />
      </PostImage>

      <Description>{post.description}</Description>
      <Article>
        <Markdown>{post.content}</Markdown>
      </Article>
    </BlogHomeWrapper>
  );
};

export default BlogHome;
