import React from 'react';
import { Button, Form, Input, Radio } from 'antd';

import ListDescription from 'components/ListDescription';
import constants from 'data/constants';
import MainLayout from 'components/MainLayout';
import SectionTitle from 'components/SectionTitle';
import SectionSubtitle from 'components/SectionSubtitle';
import Footer from 'components/Footer';

import {
  ActionArea,
  ContactContent,
  ContactForm,
  DirectionArea,
  InfoAndMap,
} from './Contact.styles';

const options = [
  { label: 'Sugerencia', value: 'suggest' },
  { label: 'Queja', value: 'complain' },
  { label: 'Pregunta', value: 'question' },
];

const Contact = () => {
  const [form] = Form.useForm();
  const { COMPANY_INFO } = constants;

  const handleFinish = (model) => {
    console.log(model);
  };
  return (
    <>
      <MainLayout>
        <ContactContent>
          <InfoAndMap>
            <SectionTitle>Manténgase en contacto</SectionTitle>
            <SectionSubtitle style={{ textAlign: 'left' }}>
              ¿Necesitas contactarnos? Aquí te dejamos todas las vías
            </SectionSubtitle>

            <DirectionArea>
              <ListDescription
                items={[
                  <span>{COMPANY_INFO.EMAIL.value}</span>,
                  <span>{COMPANY_INFO.PHONE.value}</span>,
                  <span>{COMPANY_INFO.ADDRESS.value}</span>,
                ]}
              />
              <iframe
                title="Ubicación de Bantel"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2837.6757574960843!2d-66.85458090361948!3d10.4852406733718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c2a5976abe951d9%3A0xf0a161068f1ae28b!2sC.C.C.T.%20Centro%20Comercial%20Ciudad%20Tamanaco!5e0!3m2!1ses!2sve!4v1632772421271!5m2!1ses!2sve"
                height="450"
                style={{ border: 0, width: '100%' }}
                loading="lazy"
              />
            </DirectionArea>
          </InfoAndMap>
          <ContactForm form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
              label="¿Cuál es el motivo por el que nos contacta?"
              name="reason"
              style={{ width: '100%' }}
              rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            >
              <Radio.Group options={options} optionType="button" buttonStyle="solid" />
            </Form.Item>

            <Form.Item
              label="Su nombre y apellido"
              name="name"
              style={{ width: '100%' }}
              rules={[{ required: true, message: 'Este campo es obligatorio' }]}
            >
              <Input placeholder="Ej. Juan Contreras" />
            </Form.Item>

            <Form.Item
              label="Su correo electrónico"
              name="email"
              style={{ width: '100%' }}
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
                { type: 'email', message: 'Escriba un e-mail válido' },
              ]}
            >
              <Input placeholder="Ej. juancontreras@dominio.com" />
            </Form.Item>

            <Form.Item
              label="Sus comentarios"
              name="comments"
              style={{ width: '100%' }}
              rules={[
                { required: true, message: 'Este campo es obligatorio' },
                { type: 'email', message: 'Escriba un e-mail válido' },
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <ActionArea>
              <Button type="primary">Enviar</Button>
            </ActionArea>
          </ContactForm>
        </ContactContent>
      </MainLayout>

      <Footer />
    </>
  );
};

export default Contact;
