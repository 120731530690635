import React from 'react';

import Logo from 'assets/img/logo.svg';
import constants from 'data/constants';
import ListDescription from 'components/ListDescription';
import {
  DownFooterArea,
  FooterLogo,
  FooterTexts,
  FooterWrapper,
  Link,
  MainFooterArea,
} from './Footer.styles';

const Footer = () => {
  const { COMPANY_INFO } = constants;
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper>
      <MainFooterArea>
        <FooterLogo>
          <img src={Logo} alt="Bantel Logo" />
        </FooterLogo>
        <FooterTexts>
          <ListDescription
            title="Enlaces"
            items={[
              <Link to="/about"> Sobre nosotros</Link>,
              <Link to="/products">Productos y Servicios</Link>,
              <Link to="/blog">Blog</Link>,
              <Link to="/contact">Contacto</Link>,
            ]}
          />
          <ListDescription
            title="Mantengase en contacto"
            items={[
              <span>{COMPANY_INFO.EMAIL.value}</span>,
              <span>{COMPANY_INFO.PHONE.value}</span>,
              <span>{COMPANY_INFO.ADDRESS.value}</span>,
            ]}
          />
        </FooterTexts>
      </MainFooterArea>
      <DownFooterArea>
        &copy; {currentYear} Telecomunicaciones Bantel C.A. Reservados todos los derechos.
      </DownFooterArea>
    </FooterWrapper>
  );
};

export default Footer;
