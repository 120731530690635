import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { device } from 'assets/styles/theme';

export const FooterWrapper = styled.div`
  width: 100%;
`;

export const MainFooterArea = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.primary50};
  display: grid;
  gap: 48px;
  @media ${device.xss} {
    padding: 32px;
    grid-template-columns: 1fr;
  }

  @media ${device.xs} {
    padding: 48px;
  }

  @media ${device.sm} {
    padding: 64px;
  }

  @media ${device.md} {
    grid-template-columns: 1fr 2fr;
  }
`;

export const DownFooterArea = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.primary100};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  font-size: 0.8em;
`;

export const FooterLogo = styled.div`
  height: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    height: 100%;
  }
`;

export const FooterTexts = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-evenly;
`;

export const Link = styled(GatsbyLink)`
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: none;
`;
