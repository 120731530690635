const constants = {
  COMPANY_INFO: {
    EMAIL: {
      label: 'Email',
      value: 'info@bantel.net.ve',
    },
    PHONE: {
      label: 'Teléfono',
      value: '+58 212-959.90.40',
    },
    ADDRESS: {
      label: 'Dirección',
      value:
        'Telecomunicaciones Bantel C.A. Av. La Estancia, CCCT - Torre C. Piso 13. Oficina 1304. Urb. Chuao – Caracas. Edo. Miranda Zona Postal 1060 Venezuela',
    },
  },
};

export default constants;
