import { device } from 'assets/styles/theme';
import styled from 'styled-components';

export const SectionSubtitleWrapper = styled.div`
  color: ${({ theme }) => theme.secondary600};
  text-align: center;

  @media ${device.xss} {
    font-size: 1em;
  }

  @media ${device.md} {
    font-size: 1.3em;
  }
`;

export const SectionSubtitleText = styled.div``;
