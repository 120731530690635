import { device } from 'assets/styles/theme';
import styled from 'styled-components';

export const PageSectionWrapper = styled.div`
  width: 100%;
  background-color: ${(props) => (props.colored ? ({ theme }) => theme.primary50 : 'white')};
  @media ${device.xss} {
    padding: 32px;
  }

  @media ${device.xs} {
    padding: 48px;
  }

  @media ${device.sm} {
    padding: 64px;
  }

  @media ${device.md} {
    padding: 96px;
  }

  @media ${device.lg} {
    padding-left: 128px;
    padding-right: 128px;
  }

  @media ${device.xl} {
    padding-left: 192px;
    padding-right: 192px;
  }

  @media ${device.xxl} {
    padding-left: 384px;
    padding-right: 384px;
  }
`;

export const SectionWrappertext = styled.div``;
