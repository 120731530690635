import React, { useState } from 'react';

import { Link as GatsbyLink } from 'gatsby';

import LogoCircle from 'assets/img/logo.svg';
import { MenuOutlined } from '@ant-design/icons';
import {
  activeLinkStyles,
  Link,
  LinksArea,
  Logo,
  LogoArea,
  MenuBarWrapper,
  MenuButton,
} from './MenuBar.styles';

const MenuBar = () => {
  const [showMenuBar, setShowMenuBar] = useState(false);
  return (
    <MenuBarWrapper>
      <LogoArea>
        <Logo src={LogoCircle} />
        <MenuButton
          icon={<MenuOutlined />}
          shape="circle"
          onClick={() => setShowMenuBar(!showMenuBar)}
        />
      </LogoArea>
      <LinksArea className={showMenuBar ? 'visible' : 'hidden'}>
        <Link as={GatsbyLink} to="/" activeStyle={activeLinkStyles}>
          Inicio
        </Link>
        <Link as={GatsbyLink} to="/about" activeStyle={activeLinkStyles}>
          Sobre nosotros
        </Link>
        <Link as={GatsbyLink} to="/products" activeStyle={activeLinkStyles}>
          Productos y servicios
        </Link>
        <Link as={GatsbyLink} to="/blog" activeStyle={activeLinkStyles}>
          Blog
        </Link>
        <Link as={GatsbyLink} to="/contact" activeStyle={activeLinkStyles}>
          Contacto
        </Link>
      </LinksArea>
    </MenuBarWrapper>
  );
};

export default MenuBar;
