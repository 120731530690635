import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import {
  ListDescriptionList,
  ListDescriptionListItem,
  ListDescriptionTitle,
  ListDescriptionWrapper,
} from './ListDescription.styles';

const ListDescription = ({ title, items }) => {
  return (
    <ListDescriptionWrapper>
      <ListDescriptionTitle>{title}</ListDescriptionTitle>
      <ListDescriptionList>
        {items.map((item, index) => {
          const key = index;
          return (
            <ListDescriptionListItem key={key}>
              <CheckCircleOutlined />
              <span>{item}</span>
            </ListDescriptionListItem>
          );
        })}
      </ListDescriptionList>
    </ListDescriptionWrapper>
  );
};

export default ListDescription;
