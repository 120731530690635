import { defaultValues, deviceMax } from 'assets/styles/theme';
import styled from 'styled-components';
import { Button } from 'antd';

export const MenuBarWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 96px;
  justify-content: space-between;

  @media ${deviceMax.md} {
    flex-direction: column;
    height: initial;
    min-height: 64px;
  }
`;

export const LogoArea = styled.div`
  display: block;
  height: 100%;
  @media ${deviceMax.md} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Logo = styled.img`
  height: 100%;
  max-height: 96px;
`;

export const MenuButton = styled(Button)`
  display: none;
  @media ${deviceMax.md} {
    display: block;
  }
`;

export const LinksArea = styled.div`
  display: flex;
  text-align: center;
  & a:last-child {
    margin-right: 0;
  }

  @media ${deviceMax.md} {
    width: 100%;
    margin-top: 24px;
    flex-direction: column;
    padding: 24px;
    background-color: rgb(0 0 0 / 3%);
    border-radius: 12px;
    & a:last-child {
      margin-bottom: 0;
    }
    &.hidden {
      display: none;
    }
  }
`;

export const Link = styled.div`
  margin-left: 24px;
  margin-right: 24px;
  color: ${({ theme }) => theme.primaryColor};
  text-decoration: none;

  @media ${deviceMax.md} {
    margin-left: initial;
    margin-right: initial;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;

export const activeLinkStyles = {
  fontWeight: 'bold',
  color: defaultValues.secondary600,
};
