import styled from 'styled-components';

export const OfferWrapper = styled.div`
  padding: 24px;
  background-color: white;
  border: solid 1px ${({ theme }) => theme.primary200};
  border-radius: 4px;
  display: grid;
  column-gap: 24px;
  grid-template-columns: 64px auto;
`;

export const OfferIconArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > .anticon {
    font-size: 48px;
    color: ${({ theme }) => theme.secondary300};
  }
`;

export const OfferTextArea = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OfferTitle = styled.div`
  color: ${({ theme }) => theme.primary500};
  font-weight: bold;
`;
export const OfferDescription = styled.div`
  margin-top: 8px;
  font-size: 0.8em;
`;
