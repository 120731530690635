import React from 'react';

import ProductsImage from 'assets/img/productsMain.png';
import ProductImage1 from 'assets/img/productIcon1.svg';
import ProductImage2 from 'assets/img/productIcon2.svg';
import OtherServiceIcon1 from 'assets/img/otherServiceIcon1.svg';
import OtherServiceIcon2 from 'assets/img/otherServiceIcon2.svg';
import OtherServiceIcon3 from 'assets/img/otherServiceIcon3.svg';
import ListDescription from 'components/ListDescription';

import MainLayout from 'components/MainLayout';
import LayoutImageTitlePage from 'components/LayoutImageTitlePage';
import PageSection from 'components/PageSection';
import Footer from 'components/Footer';

import OtherServiceItem from './OtherServiceItem';

import {
  CenteredTextTitle,
  OtherServices,
  PrimaryServiceImage,
  PrimaryServices,
  PrimaryServiceTextArea,
  PrimaryServiceTextTitle,
  ProductsContent,
  ServiceParagraph,
  ServiceSubtitle,
} from './Products.styles';

const Products = () => {
  return (
    <>
      <MainLayout>
        <LayoutImageTitlePage
          title="Productos y Servicios"
          subtitle="Servicios de calidad y rendimiento para su empresa"
          image={ProductsImage}
        />
      </MainLayout>
      <PageSection>
        <ProductsContent>
          <p>
            En Telecomunicaciones Bantel contamos con una serie de servicios llevados a cabo con
            plataformas de transporte terrestre y satelital basadas en IP. La innovación es la
            principal característica de lo que te ofrecemos, por lo que estas plataformas son de
            última generación diseñadas para optimizar el desempeño de las aplicaciones de datos,
            voz, video y valor agregado a la red de redes.
          </p>
        </ProductsContent>
      </PageSection>
      <PageSection colored>
        <PrimaryServices>
          <PrimaryServiceImage>
            <img src={ProductImage1} alt="Servicio" />
          </PrimaryServiceImage>
          <PrimaryServiceTextArea>
            <PrimaryServiceTextTitle>Enlaces Terrestres</PrimaryServiceTextTitle>
            <ServiceParagraph>
              Radios microondas en bandas licenciadas de 8, 18 y 23 Ghz y en banda no licenciadas de
              5 Ghz, todos entregados en interfaz GigaEthernet.
            </ServiceParagraph>
            <ServiceSubtitle>Transporte de datos, voz y video</ServiceSubtitle>
            <ServiceParagraph>
              Servicios simétricos los planes son 5Mbps hasta 300Mbps simétricos
            </ServiceParagraph>
            <ServiceSubtitle>Transporte de valor agregado a la red de redes</ServiceSubtitle>

            <ListDescription
              items={[
                <p>Para servicio simétrico, los planes comienzan desde los 5Mbps hasta 300Mbps</p>,
                <p>
                  Para servicio asimétrico, los planes comienzan desde los 5Mbps hasta 50Mbps para
                  la descarga y contención 2:1 para la carga.
                </p>,
                <p>
                  Opción de IP pública fija Geo localizada en Venezuela por Lacnic o IP publica Geo
                  localizada en USA por la Arin.
                </p>,
              ]}
            />
          </PrimaryServiceTextArea>
        </PrimaryServices>
        <PrimaryServices lastItem>
          <PrimaryServiceImage>
            <img src={ProductImage2} alt="Servicio" />
          </PrimaryServiceImage>
          <PrimaryServiceTextArea>
            <PrimaryServiceTextTitle>Enlaces Satelitales</PrimaryServiceTextTitle>
            <ServiceParagraph>
              Los enlaces son soportados por la más reciente plataforma de iDirect Velocity,
              utilizando la Banda Ku con un satélite de última generación HTS de Intelsat; además,
              utilizamos estaciones terrenas (Vsat) con antenas de 1,2m y 0,74cm respectivamente con
              un modem router en las premisas del cliente, entregando una interfaz GigaEthernet.
            </ServiceParagraph>
            <ServiceSubtitle>Transporte de datos, voz y video</ServiceSubtitle>
            <ServiceParagraph>
              Servicios simétricos configurados con mayor esfuerzo en planes que van desde los
              512kbps hasta 1Mbps. Servicios asimétricos configurados con mayor esfuerzo en planes
              que van desde 1Mbps hasta 5Mbps para la descarga y desde 256kbps hasta 1Mbps para
              carga.
            </ServiceParagraph>
            <ServiceSubtitle>Transporte de valor agregado a la red de redes</ServiceSubtitle>
            <ServiceParagraph>
              Servicios asimétricos configurados con mayor esfuerzo en planes que van desde 1Mbps
              hasta 10Mbps para la descarga y desde 256kbps hasta 1,2Mbps para carga. Opción de IP
              publica fija Geo localizada en Venezuela por Lacnic o IP publica Geo localizada en USA
              por la Arin.
            </ServiceParagraph>
          </PrimaryServiceTextArea>
        </PrimaryServices>
      </PageSection>
      <PageSection>
        <CenteredTextTitle>Otros Servicios</CenteredTextTitle>
        <OtherServices>
          <OtherServiceItem
            image={OtherServiceIcon1}
            title="Multicast para data, video y audio streaming"
            description="Servicio para distribución de data (actualización masiva y simultánea de servers), video (envío de contenido de audio y video simultáneo en múltiples locaciones)."
          />
          <OtherServiceItem
            image={OtherServiceIcon2}
            title="Respaldo de redes de transporte terrestre on demand"
            description="Este servicio se diseña a pedido, y consiste en tener un segundo enlace para cada oficina o agencia que se elija como crítica y que se comunica con un sistema central, asimismo, está basado en el número de incidencias de la red primaria determinada por la capacidad que podría demandar en un momento dado, y esta capacidad sería utilizada por la oficina o agencia que esté en contingencia, y una vez liberada el enlace primario será restablecido."
          />
          <OtherServiceItem
            lastItem
            image={OtherServiceIcon3}
            title="Servicio de transporte transaccional, para puntos de ventas, cajeros y sistemas scada"
            description="Servicios para transportar datos transaccionales con planes simétricos que van desde 128kbps a 512kbps. Estos son instalados con antenas 0,74cm y se pueden entregar con un kit ''instálalo tú mismo''."
          />
        </OtherServices>
      </PageSection>
      <Footer />
    </>
  );
};

export default Products;
