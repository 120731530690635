import React from 'react';
import {
  LayoutImageTitlePageImgWrapper,
  LayoutImageTitlePageSubtitle,
  LayoutImageTitlePageTitle,
  LayoutImageTitlePageWrapper,
} from './LayoutImageTitlePage.styles';

const LayoutImageTitlePage = ({ title, subtitle, image }) => {
  return (
    <LayoutImageTitlePageWrapper>
      <LayoutImageTitlePageTitle>{title}</LayoutImageTitlePageTitle>
      <LayoutImageTitlePageSubtitle>{subtitle}</LayoutImageTitlePageSubtitle>
      <LayoutImageTitlePageImgWrapper>
        <img src={image} alt="Acerca de nosotros" />
      </LayoutImageTitlePageImgWrapper>
    </LayoutImageTitlePageWrapper>
  );
};

export default LayoutImageTitlePage;
